import { OperatingSystem } from "../src/constants";
import { isEmptyOrNull } from "./textUtils";
import { ANALYTICS_CONSTANTS } from '../src/constants/index';
interface linkObj {
    hrefValue: string;
    isAkamai: boolean;
}

export const linkResolver = (iosdownloadlink) => {

    if (osDetector() === OperatingSystem.IOS) {
        return {
            hrefValue: iosdownloadlink,
            isAkamai: false
    }
    }
    let x = Math.random() * 100;
    let akamai = 100;
    let controlPercentage = parseInt(process.env.GATSBY_CONTROL_ABTEST);
    if (!process.env.GATSBY_CONTROL_ABTEST || isEmptyOrNull(process.env.GATSBY_TEST_LINK)) {
        controlPercentage = 100
    }
    let linkObj = {
        hrefValue: "",
        isAkamai: false
    }
    
    

    const setLinkObj = (hrefValue,isAkamai)=>{

        
        
        linkObj.hrefValue = hrefValue;
        linkObj.isAkamai = isAkamai;

        if(isAkamai){
            setTimeout(()=>{
                let dataLayer = window.dataLayer || [];
                dataLayer.push({
                    event: ANALYTICS_CONSTANTS.EVENT,
                    category: ANALYTICS_CONSTANTS.PAGE_LOAD_EVENT.CATEGORY + "_CDN",
                    action: ANALYTICS_CONSTANTS.PAGE_LOAD_EVENT.ACTION + "_CDN",
                    label: ANALYTICS_CONSTANTS.PAGE_LOAD_EVENT.LABEL + "_CDN"
                });
            },0)
            
        }
            
    }

    if (x < controlPercentage) {
        if (akamai < 5) {
            setLinkObj("https://zupeestatic.akamaized.net/apk/v2/install-ludo-supreme.apk",true);  
        }
        else {
            setLinkObj(process.env.GATSBY_CONTROL_LINK,false);
        }

    } else {
        if (akamai < 5) {
            setLinkObj("https://zupeestatic.akamaized.net/apk/v2/install-ludo-supreme.apk",true);
        }
        else {
            setLinkObj(process.env.GATSBY_TEST_LINK,false);
        }

    }
    console.log("control Link : ", process.env.GATSBY_CONTROL_LINK, "test Link : ", process.env.GATSBY_TEST_LINK, "controlPercentage : ", controlPercentage, "apk link : ", linkObj.hrefValue, "akamai var : ", akamai, "ab test random variable : ", x)
    return linkObj;
}
export const osDetector = () => {
    var ua = navigator.userAgent.toLowerCase();

    if (ua.indexOf("android") > -1) return OperatingSystem.ANDROID
    if (ua.indexOf("iphone") > -1) return OperatingSystem.IOS
    return OperatingSystem.OTHERS
}