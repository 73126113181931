import _map from 'lodash/map';
import React, { useRef, useState, useLayoutEffect } from 'react';
import { EMPTY_NUMBER } from '../../../constants/empty';
import { MOBILE_BREAKPOINT_WIDTH } from '../../../constants/index';

export const useGenericSlider = (props) => {
	const {
		componentArray,
		numberOfComponents,
		isAutoScrollEnabled,
		autoScrollTimer,
		sliderButton,
		isSliderButtonEnabled,
		animationType
	} = props;
	const [currentIndex, setCurrentIndex] = useState(EMPTY_NUMBER);
	const [currentScrollPosition, setScrollPostion] = useState(EMPTY_NUMBER);
	const [width, setWidth] = useState('100%');
	const [itemsDisplayed, setItemsDisplayed] = useState(numberOfComponents);
	const intervalRef = useRef(null);
	const [startTouchX, setStartTouchX] = useState(EMPTY_NUMBER);

	useLayoutEffect(() => {
		const configureSldier = () => {
			const isBrowser = typeof window !== 'undefined';

			if (isBrowser) {
				if (window?.innerWidth > 1100) {
					setWidth(`${100 / numberOfComponents}%`);
				} else if (window?.innerWidth > MOBILE_BREAKPOINT_WIDTH && window?.innerWidth < 1100) {

					setItemsDisplayed(Math.min(numberOfComponents, 2));
					setWidth(`${100 / Math.min(numberOfComponents, 2)}%`);
				} else {
					setItemsDisplayed(Math.min(numberOfComponents, 1));
					setWidth(`${100 / Math.min(numberOfComponents, 1)}%`);
				}
			}
		};
		setTimeout(() => {
			handleAutoScroll();
		}, 0);
		configureSldier();
		window.addEventListener('resize', configureSldier);

		return () => {
			window.removeEventListener('resize', configureSldier);
			clearInterval(intervalRef.current);
		};
	}, []);
	const handleLeftClick = () => {
		const arrayLength = componentArray.length;
		if (currentIndex > 0) {
			setCurrentIndex((currentIndex) => {
				return currentIndex - 1;
			});
			setScrollPostion(currentScrollPosition + divref.current.offsetWidth);
		}
	};
	const handleRightClick = () => {
		const arrayLength = componentArray.length;
		const finalIndex = currentIndex + itemsDisplayed;
		if (finalIndex < arrayLength) {
			setCurrentIndex((currentIndex) => {
				return currentIndex + 1;
			});
			setScrollPostion(currentScrollPosition - divref.current.offsetWidth);
		}
	};
	const handleAutoScroll = () => {
		if (isAutoScrollEnabled) {
			setInterval(() => {
				const arrayLength = componentArray.length;

				if (currentIndex < arrayLength - itemsDisplayed) {
					setCurrentIndex((currentIndex) => {
						if (currentIndex < arrayLength - itemsDisplayed) {
							setScrollPostion(-(currentIndex + 1) * divref.current.offsetWidth);
							return currentIndex + 1;
						}
						setScrollPostion(0);
						return 0;
					});
				}
			}, autoScrollTimer ?? 5000);
		}
	};
	const divref = useRef(null);
	const handleTouchStart = (event) => {
		setStartTouchX(event.changedTouches[0].screenX);
	};

	const handleTouchEnd = (event) => {
		const movingX = event.changedTouches[0].screenX;

		if (movingX > startTouchX + 30) {
			handleLeftClick();
		} else if (movingX < startTouchX - 30) {
			handleRightClick();
		}
	};
	return {
		componentArray,

		sliderButton,
		isSliderButtonEnabled,
		animationType,
		handleTouchStart,
		handleTouchEnd,
		currentIndex,
		currentScrollPosition,
		itemsDisplayed,
		intervalRef,
		startTouchX,
		width,
		handleLeftClick,
		handleRightClick,
		divref
	};
};

export default useGenericSlider;
