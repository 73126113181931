import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import './accordian.modules.css';
import { TextProps } from '../../../types/types';

interface AccordianProps {
	title: TextProps;
	accordianImageUp: any;
	accordianImageDown: any;
}

const Accordian: React.FC<AccordianProps> = ({ title, children, accordianImageUp, accordianImageDown }) => {
	const [accordianOpen, setAccordianOpen] = useState(false);

	const toggleAccordian = () => {
		setAccordianOpen(!accordianOpen);
	};

	//use hooks to make styles
	return (
		<div className='accordian-wrapper'>
			<div onClick={toggleAccordian} className='accordian-title'>
				<p className='accordian-title-text' style={{ color: title.color }}>
					{title.text}
				</p>
				<span className='arrow-icon'>
					{accordianOpen
						? accordianImageUp && <GatsbyImage image={accordianImageUp} alt='' />
						: accordianImageDown && <GatsbyImage image={accordianImageDown} alt='' />}
				</span>
			</div>
			<div className={`accoridan-content ${accordianOpen ? 'accordian-open' : ''}`}>{children}</div>
		</div>
	);
};
export default Accordian;
