import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';


import { SliderButtonsProps } from '../../../types/types';

interface CustomSliderButtonsProps {
	handleLeftClick: () => void;
	handleRightClick: () => void;
	sliderButton?: SliderButtonsProps;
}

const SliderButton = (props: CustomSliderButtonsProps) => {
	const {
		sliderButton,

		handleLeftClick,
		handleRightClick
	} = props;

	return (
		<div
			style={{ backgroundColor: sliderButton?.backgroundColor }}
			className='slider-btn-container generic-slider-button-container'>
			<div
				style={{ backgroundColor: sliderButton?.leftButton?.color }}
				onClick={handleLeftClick}
				className='generic-slider-button-div'>
				<GatsbyImage image={sliderButton?.leftButton?.image.imageFile} alt={sliderButton?.leftButton?.image?.altText} className='generic-slider-button-image' />
			</div>
			<div
				style={{ backgroundColor: sliderButton?.leftButton?.color }}
				onClick={handleRightClick}
				className='generic-slider-button-div'>
				<GatsbyImage image={sliderButton?.rightButton?.image.imageFile} alt={sliderButton?.rightButton?.image.altText} className='generic-slider-button-image' />
			</div>
		</div>
	);
};

export default SliderButton;
