import {
	ImageWithUrl,
	SingleSliderButton,
	SliderButtonsProps,
	TestimonialSectionProps,
	UserDescriptionProps,
	UserTestimonialProps
} from '../../../types/types';

export const processTestimonialSectionData = (data: any, page: string): TestimonialSectionProps => {
	const myData = data.allWpPage.edges[0].node[page][`${page}Testimonials`];
	const downloadAndroidImage: ImageWithUrl = {
		image: {
			imageFile: myData?.downloadandroidimage?.image?.imagefile?.localFile?.childImageSharp?.gatsbyImageData,
			altText: myData?.downloadandroidimage?.image?.alttext
		},
		url: myData?.downloadandroidimage?.url
	};
	const downloadIosImage: ImageWithUrl = {
		image: {
			imageFile: myData.downloadiosimage?.image?.imagefile?.localFile?.childImageSharp?.gatsbyImageData,
			altText: myData.downloadiosimage?.image?.alttext
		},
		url: myData?.downloadiosimage?.url
	};
	const leftButton: SingleSliderButton = {
		image: {
			imageFile: myData?.sliderbutton?.lefticon?.image?.imagefile?.localFile?.childImageSharp?.gatsbyImageData,
			altText: myData?.sliderbutton?.lefticon?.image?.alttext
		},
		color: myData?.sliderbutton?.lefticon?.color
	};
	const rightButton: SingleSliderButton = {
		image: {
			imageFile: myData?.sliderbutton?.righticon?.image?.imagefile?.localFile?.childImageSharp?.gatsbyImageData,
			altText: myData?.sliderbutton?.righticon?.image?.alttext
		},
		color: myData?.sliderbutton?.righticon?.color
	};
	const sliderButtonData: SliderButtonsProps = {
		leftButton: leftButton,
		rightButton: rightButton,
		backgroundColor: myData?.sliderbutton?.backgroundcolor
	};
	const testimonials = myData?.usertestimonials;
	const testimonialArray = [];

	testimonials.forEach((testimonialData, index) => {
		const testimonial = testimonialData.testimonial;

		const userDescription = testimonial?.userdescription;
		const userDescriptionData: UserDescriptionProps = {
			name: { ...userDescription?.name },
			location: { ...userDescription?.location },
			profilePicture: {
				imageFile: userDescription?.profilepicture?.imagefile?.localFile?.childImageSharp?.gatsbyImageData,
				altText: userDescription?.profilepicture?.alttext
			}
		};
		const testimonialValue: UserTestimonialProps = {
			testimonialText: { ...testimonial?.testimonialtext },
			wonText: { ...testimonial?.wontext },
			userDescription: userDescriptionData,
			borderColor: myData?.bordercolor,
			wonTextBackground: myData?.wontextbackgroundcolor
		};
		testimonialArray.push(testimonialValue);
	});
	const testimonialSectionData: TestimonialSectionProps = {
		backgroundColor: myData.background.color,
		backgroundImage: myData.background?.image?.localFile?.childImageSharp?.gatsbyImageData,
		...(downloadAndroidImage.url && { downloadAndroidImage }),
		...(downloadIosImage.url && { downloadIosImage }),
		sliderButton: sliderButtonData,
		...(myData.ratingimage?.imagefile && {
			ratingImage: {
				imageFile: myData.ratingimage?.imagefile?.localFile?.childImageSharp?.gatsbyImageData,
				altText: myData.ratingimage?.alttext
			}
		}),
		...(myData.titletext && { titleText: { ...myData.titletext } }),
		userTestimonials: testimonialArray,
		winnerTitle: myData?.winnertitle
	};

	return testimonialSectionData;
};

export default processTestimonialSectionData;
