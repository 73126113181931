import React from 'react'

import UserDescription from './UserDescription'
import { UserTestimonialProps } from '../../../types/types'

const UserTestimonial = (props: UserTestimonialProps) => {
     const { userDescription, wonText, testimonialText, wonTextBackground, borderColor } = props
     if (!userDescription || !wonText || !testimonialText) {
          return null;
     }
     return (<div style={{ borderColor: borderColor }} className="user-testimonials-container">
          <div className="user-testimonials-description-container">
               <UserDescription {...userDescription} />
          </div>
          <div style={{ backgroundColor: wonTextBackground }} className="user-testimonials-won-amount-text-div" >
               <p style={{ color: wonText.color }} className="user-testimonials-won-amount-text-field testimonial-p-tag">{wonText.text}</p>
          </div>
          <div className="user-testimonials-main-text-div">
               <p style={{ color: testimonialText.color }} className="user-testimonials-main-text-field testimonial-p-tag">{testimonialText?.text}</p>
          </div>
     </div>)
}
export default UserTestimonial