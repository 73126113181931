import _map from 'lodash/map';

export const processFaqSectionData = (data: any, page: string) => {
	const faqData = data.allWpPage?.edges[0]?.node?.[page]?.[`${page}Faq`];

	const faqList = _map(faqData?.faqs ?? [], (faq) => faq.faq);
	const accordianImageDown = faqData?.accordianimagedown?.localFile?.childImageSharp?.gatsbyImageData;
	const accordianImageUp = faqData?.accordianimageup?.localFile?.childImageSharp?.gatsbyImageData;
	const title = faqData?.titletext;
	const backgroundColor = faqData?.background.color;
	const mobileBackground = faqData?.mobilebackground;

	return {
		faqList,
		title,
		accordianImageDown,
		accordianImageUp,
		backgroundColor,
		mobileBackground
	};
};

export default processFaqSectionData;
