import React from 'react';

import PageContext from './pageContext';
import usePageProvider from './usePageProvider';

interface PageProviderProps {
	children: any;
	page: string;
	data: any;
	downloadLink: string;
	isAkamai: boolean;
}
const PageProvider: React.FC<PageProviderProps> = ({ children, page, data, downloadLink, isAkamai }) => {
	const { pageName } = usePageProvider({ page });
	return <PageContext.Provider value={{ page: pageName, data, downloadLink, isAkamai }}>{children}</PageContext.Provider>;
};
export default PageProvider;
