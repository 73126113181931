import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { DownloadButtonData } from '../../../types/types';
import { ANALYTICS_CONSTANTS, THANKYOU_PAGE_PATH } from '../../../constants';
import AnalyticsContext from '../../../context/analytics/analyticsContext';
import PageContext from '../../../context/page/pageContext';


const DownloadButton = (props: DownloadButtonData) => {
	const analyticsPrefix = useContext(AnalyticsContext);
	const {isAkamai} = useContext(PageContext);
	const handleDownload = (e) => {


		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: analyticsPrefix + ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.CATEGORY + (isAkamai ? "_CDN" : ""),
			action: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.ACTION + (isAkamai ? "_CDN" : ""),
			label: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.LABEL + (isAkamai ? "_CDN" : "")
		});
		navigate(THANKYOU_PAGE_PATH);
	};
	return (
		// <div className="hero-section-download-btn-container">
		// {/* <input type="number" style={{ color: props.placeholderText?.color }} placeholder={props.placeholderText.text} className="hero-section-input-field input-field-no-spin" maxLength={10} /> */}
		<a className='hero-section-download-link apk-download-link' download onClick={(e) => handleDownload(e)}>
			<div style={{ backgroundColor: props?.button?.buttonColor }} className='hero-section-download-btn-container'>
				{props.button.buttonAndroidImage && (
					<GatsbyImage
						image={props.button.buttonAndroidImage.imageFile}
						alt={props.button.buttonAndroidImage.altText}
						className='hero-section-os-image-field'
					/>
				)}
				<p style={{ color: props?.button?.buttonText?.color }} className='hero-section-download-text'>
					{props?.button?.buttonText?.text}
				</p>
			</div>
		</a>

		// </div>
	);
};

export default DownloadButton;
