import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import './heroSection.modules.css';
import { ImageWithAltText, MainDescriptionProps } from '../../../types/types';
import processMainDescriptionData from './helper';
import WinningTextComponent from './WinningText';
import DownloadButton from './DownloadButton';
import GenericSlider from '../../fields/genericSlider';
import { breakLines } from '../../../../utils/textUtils';
import PageContext from '../../../context/page/pageContext';

const constructImageArray = (images: Array<ImageWithAltText>): Array<React.FC> => {
	const componentArray = [];

	images?.map((image, index) => {
		componentArray.push(
			<div className='hero-section-slider-image-container'>
				<GatsbyImage image={image.imageFile} alt={image.altText} key={`hero-section-${index}`} />
			</div>
		);
	});
	return componentArray;
};
const MainDescription = (props: MainDescriptionProps) => {
	const { data, page } = useContext(PageContext);

	const processedData = processMainDescriptionData(data, page);

	const { imageSlider, backgroundColor } = processedData;
	const componentArray = constructImageArray(imageSlider?.imageArray);
	return (
		<div className='mainContainer' style={{ backgroundColor: backgroundColor }}>
			<div className='hero-section-left-container'>
				<h1 style={{ color: processedData?.titleText?.color, whiteSpace: 'pre-wrap' }} className='main-text'>
					{breakLines(processedData?.titleText?.text.toString())}
				</h1>
				<p style={{ color: processedData?.subtitleText?.color }} className='sub-text hero-p-tag'>
					{processedData?.subtitleText?.text}
				</p>
				{processedData.downloadButtonData && processedData.winningText && (
					<div className='download-button-desktop'>
						<DownloadButton {...processedData.downloadButtonData} />
						<WinningTextComponent {...processedData.winningText} />
					</div>
				)}
			</div>
			<div className='hero-section-right-container'>
				<div className='hero-section-slider-container'>
					<GenericSlider
						componentArray={componentArray}
						numberOfComponents={1}
						isSliderButtonEnabled={false}
						isAutoScrollEnabled={false}
						animationType={'fadeIn'}
						autoScrollTimer={imageSlider.scrollTimer}
					/>
				</div>
			</div>
		</div>
	);
};

export default MainDescription;
