import React, { useContext } from 'react';

import FaqItem from './FaqItem';
import './faq.modules.css';
import processFaqSectionData from './helper';
import PageContext from '../../../context/page/pageContext';

const FaqSection = (props) => {
	const { data, page } = useContext(PageContext);

	const processedData = processFaqSectionData(data, page);
	const { faqList = [], title = {}, accordianImageDown, accordianImageUp, backgroundColor, mobileBackground } = processedData;
	const { titletext = 'FAQs', titlecolor = 'white' } = title;

	//use hooks to make styles
	return (
		<div className='faq-container' style={{ background: mobileBackground }}>
			<h2 className='faq-heading-text' style={{ color: titlecolor }}>
				{titletext}
			</h2>
			<ul className='faq-list'>
				{faqList.map((faq, index) => (
					<li className='faq-list-item' key={index}>
						<FaqItem
							accordianImageDown={accordianImageDown}
							accordianImageUp={accordianImageUp}
							description={faq?.descriptiontext}
							title={faq?.titletext}
						/>
						<hr className='line' />
					</li>
				))}
			</ul>
		</div>
	);
};
export default FaqSection;
