import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { UserDescriptionProps } from "../../../types/types"


const UserDescription = (props: UserDescriptionProps) => {
    const { profilePicture, name, location } = props
    if (!name || !location || !profilePicture) {
        return null;
    }
    return (

        <div className="user-description-container">
            {profilePicture && <div className="user-description-image-container"><GatsbyImage image={profilePicture.imageFile} alt={profilePicture.altText} className="user-description-image" /></div>}
            <div className="user-description-text-div">
                <p style={{ color: name.color }} className="user-description-name-text  testimonial-p-tag">{name.text}</p>
                <p style={{ color: location.color }} className="user-description-location-text  testimonial-p-tag">{location.text}</p>
            </div>
        </div>
    )
}
export default UserDescription