import React from "react";

import { WinningText } from "../../../types/types";

const WinningTextComponent = (props: WinningText) => {
    return <div className="hero-section-winning-text-container">
        {props.image && <img src={props.image.imageFile} alt={props.image.altText} className="hero-section-winning-text-gif" style={{ width: "20px", height: "20px " }} />}
        {props.text && <p style={{ color: props.text.color }} className="hero-section-winning-text-field hero-p-tag"> {props?.text?.text}</p>}

    </div>
}


export default WinningTextComponent