import React from 'react';

import { ANIMATION_TYPES } from '../../../constants/index';
import { SliderButtonsProps } from '../../../types/types';
import useGenericSlider from './useGenericSlider';
import SliderButton from './SliderButton';
import './generic-slider.modules.css';

interface ImageSliderProps {
	componentArray: Array<React.FC>;
	numberOfComponents: number;
	isAutoScrollEnabled?: boolean;
	autoScrollTimer?: number;
	isSliderButtonEnabled?: boolean;
	sliderButton?: SliderButtonsProps;
	animationType?: string;
}
interface ContainerProps {
	component: React.FC;
	width: String;
	divref: any;
	index: number;
	isActive: boolean;
	animationType?: string;
}
const Container = (props: ContainerProps) => {
	const { divref, width, component, index, isActive, animationType } = props;
	if (animationType === ANIMATION_TYPES.FADE_IN) {
		return (
			<div
				style={{ flex: width as string, minWidth: width as string }}
				ref={divref}
				className={isActive ? 'slider-container active' : 'slider-container passive'}
				key={index}>
				<div className='slide-inner-container'>{component}</div>
			</div>
		);
	} else if (animationType === ANIMATION_TYPES.TRANSLATE_HORIZONTALLY) {
		return (
			<div
				style={{ flex: width as string, minWidth: width as string, opacity: '1' }}
				ref={divref}
				className={'slide-container'}>
				<div className='slide-inner-container'>{component}</div>
			</div>
		);
	}
	return null;
};

const GenericSlider = (props: ImageSliderProps) => {
	const {
		componentArray,

		sliderButton,
		isSliderButtonEnabled,
		animationType,
		handleTouchStart,
		handleTouchEnd,
		currentIndex,
		currentScrollPosition,
		handleLeftClick,
		handleRightClick,
		width,
		divref
	} = useGenericSlider(props);

	return (
		<div className='generic-slider-main-container'>
			<div
				className='slide-inner-container generic-slider-inner-container'
				onScroll={(e) => {
				}}>
				<div
					style={{
						transform: `translateX(${currentScrollPosition}px)`,
						transition: animationType === 'fadeIn' ? '' : 'transform ease-out 0.45s'
					}}
					className='generic-slider-animated-container'
					onTouchStart={(event) => handleTouchStart(event)}
					onTouchEnd={(event) => handleTouchEnd(event)}>
					{componentArray.map((component, index) => {
						return (
							<Container
								width={width}
								component={component}
								divref={divref}
								index={index}
								isActive={currentIndex === index}
								animationType={animationType}
								key={index}
							/>
						);
					})}
				</div>
			</div>
			{isSliderButtonEnabled && (
				<SliderButton sliderButton={sliderButton} handleLeftClick={handleLeftClick} handleRightClick={handleRightClick} />
			)}
		</div>
	);
};

export default GenericSlider;
